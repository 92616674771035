import React, { Component, Fragment } from 'react'
import {connect} from "react-redux";
import {setValues} from '../store/redux';
import {setValues as setModalValues} from '../features/modal/redux';
import Swal from 'sweetalert2'
import {imgList} from './../constants/ImagesList';
import {
    LoadingModal
} from '../features/modal';
import Header from "../features/headermain";
import Intro from "../features/intro";
import Do from "../features/do";
import Result from "../features/result";
import Footer from "../features/footer";
import styled from "styled-components";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { mdiCartOutline } from '@mdi/js';
import Icon from "@mdi/react";


class ComponentToPrint extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            layoutActive: 1,
        }
    }

    render() {
        return (
            <>
                <Page className="home">
                    <div className="col-md-12 text-center">
                        <img src={imgList.bg} alt="" width="30%" style={{marginLeft: '2%'}}/>
                    </div>
                    <div style={{width: '45%',display: 'flex',fontSize: '1.3rem'}}>
                        <div style={{width: '85%',lineHeight: '1px',color: 'black',textAlign: 'center'}}>
                            <p>#{this.props.number + 1}</p>
                        </div>
                    </div>
                    <div style={{width: '38%'}}>
                        <hr style={{border: '1px solid black'}}/>
                    </div>
                    {
                        this.props.dataBucket.map((value,key) => {
                            return (
                                <div style={{width: '45%',display: 'flex',fontSize: '1.3rem'}}>
                                    <div style={{width: '45%',lineHeight: '1px',color: 'black'}}>
                                        <p>{value.name}</p>
                                        <small style={{textTransform: 'uppercase'}}>{value.type},{value.sugarLevel}</small>
                                    </div>
                                    <div style={{width: '15%',textAlign: 'end'}}>
                                        <div style={{color: 'black'}}>
                                            <p>{value.price}</p>
                                        </div>
                                    </div>
                                    <div style={{width: '20%',textAlign: 'end'}}>
                                        <div style={{color: 'black'}}>
                                            <p>X{value.totalNumberMenu}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div style={{width: '38%'}}>
                        <hr style={{border: '1px solid black'}}/>
                    </div>
                    <div style={{width: '45%',display: 'flex',fontSize: '1.3rem'}}>
                        <div style={{width: '60%',lineHeight: '1px',color: 'black'}}>
                            <p>Total</p>
                        </div>
                        <div style={{width: '20%',textAlign: 'end'}}>
                            <div style={{color: 'black'}}>
                                <p>{this.props.totalPrice}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{width: '38%'}}>
                        <hr style={{border: '1px solid black'}}/>
                    </div>
                    <div style={{width: '45%',display: 'flex',fontSize: '1.3rem'}}>
                        <div style={{width: '85%',lineHeight: '1px',color: 'black',textAlign: 'center'}}>
                            <p>KAMI HIKōKI CAFE</p>
                            <p>TEL: 086-796-2565</p>
                            <p>FB : KAMI HIKōKI CAFE</p>
                            <p>IG : kamihikokicafe</p>
                        </div>
                    </div>
                    <div style={{paddingBottom: '160px'}}>

                    </div>
                    <hr/>
                </Page>
            </>
        )
    }
}


const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

const mapDispatchToProps = {setValues, setModalValues};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentToPrint);



const Page = styled.div`
  position: relative;
  // width: 100%;
  min-height: 100vh;
  padding: 2% 2% 0px;
  // background-image: url(${imgList.bg});
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-position: center;
  font-family: 'DB-Adman-X', 'tahoma';
 
`;