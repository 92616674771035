import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
// Page Components
import Empty from './pages/Empty';
import Contact from './pages/Contact';
import MenuTH from './pages/MenuTH';
import About from './pages/About';
import Blog from './pages/Blog';
import Content from './pages/Content';
import WebSocket from './pages/Ws';
import NewPOS from './pages/NewPOS';
import Home from './pages/Home';
import Login from './pages/Login';
import configStore from './store/ConfigStore';
// Route Middleware
import OauthMiddleware from './middleware/OauthMiddleware';
import './styles/index.scss';
const { store, persistor } = configStore();

const Routes = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <>
                    {/*<Route path={process.env.REACT_APP_EVENT_PATH+":slug"} component={OauthMiddleware}/>*/}
                    {/*<Route path={process.env.REACT_APP_EVENT_PATH} exact component={Empty}/>*/}
                    <Route path={process.env.REACT_APP_EVENT_PATH} exact component={Empty}/>
                    <Route path={process.env.REACT_APP_EVENT_PATH + "th"} exact component={MenuTH}/>
                    <Route path={process.env.REACT_APP_EVENT_PATH + "contact"} exact component={Contact}/>
                    <Route path={process.env.REACT_APP_EVENT_PATH + "about"} exact component={About}/>
                    <Route path={process.env.REACT_APP_EVENT_PATH + "blog"} exact component={Blog}/>
                    <Route path={process.env.REACT_APP_EVENT_PATH + "blog/:slug"} exact component={Content}/>
                    <Route path={process.env.REACT_APP_EVENT_PATH + "pos"} exact component={NewPOS}/>
                    <Route path={process.env.REACT_APP_EVENT_PATH + "ws"} exact component={WebSocket}/>
                    {/*<Route path={process.env.REACT_APP_EVENT_PATH+":slug/login"} exact component={Login}/>*/}
                </>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

export default Routes;
