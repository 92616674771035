import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Order from "./Order";

export default function WebSocket() {
    //Public API that will echo messages sent to it back to the client
    const [socketUrl, setSocketUrl] = useState('wss://ws.kamihikokicafe.com/ws/v1/wdyw');
    const [messageHistory, setMessageHistory] = useState([]);

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl,{protocols: process.env.REACT_APP_API_PUB_SUB});

    useEffect(() => {
        if (lastMessage !== null) {
            let lastMessageData = JSON.parse(lastMessage.data);
            const target = messageHistory.findIndex((obj => obj.id === lastMessageData.id));
            if (target === -1) {
                setMessageHistory((prev) => prev.concat(lastMessageData));
            } else {
                messageHistory[target].bucket = lastMessageData.bucket;
                messageHistory[target].total_data = lastMessageData.total_data;
                messageHistory[target].total_price = lastMessageData.total_price;
            }

        }
    }, [lastMessage, setMessageHistory]);

    const handleClickChangeSocketUrl = useCallback(
        () => setSocketUrl('wss://ws.kamihikokicafe.com/ws/v1/wdyw'),
        []
    );

    const handleClickSendMessage = useCallback(() => sendMessage(JSON.stringify({'test' : 'data1234'})), []);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    return (
        <div>
            {/*<p>*/}
            {/*    <span>The WebSocket is currently {connectionStatus}</span>*/}
            {/*</p>*/}
            {/*<p>{lastMessage ? <span>Last message: {lastMessage.data}</span> : null}</p>*/}
            {/*<p>*/}
            {/*    <ul>*/}
            {/*        {messageHistory.map((message, idx) => (*/}
            {/*            <span key={idx}>{message ? message.data : null}</span>*/}
            {/*        ))}*/}
            {/*    </ul>*/}
            {/*</p>*/}
            <Order connectionStatus={connectionStatus} messageHistory={messageHistory} lastMessage={lastMessage}/>
        </div>
    );
};