import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import {setValues} from '../store/redux';
import {setValues as setModalValues} from '../features/modal/redux';
import Swal from 'sweetalert2'
import {imgList} from './../constants/ImagesList';
import {
    LoadingModal
} from '../features/modal';
import Header from "../features/headermain";
import Intro from "../features/intro";
import Do from "../features/do";
import Result from "../features/result";
import Footer from "../features/footer";
import styled from "styled-components";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import {mdiCartOutline} from '@mdi/js';
import Icon from "@mdi/react";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";
import html2canvas from 'html2canvas';
import {jsPDF} from "jspdf";


const customStyles = {
    content: {
        fontFamily: "'DB-Adman-X', 'tahoma'",
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
};


class Order extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            layoutActive: 1,
            setIsOpen: false,
            objectSelect: '',
            checker: 0,
            messageHistoryData: []
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.lastMessage !== prevProps.lastMessage) {
            // console.log(this.props.lastMessage)
            this.setState(prevState => {
               return {
                   checker: prevState.checker + 1
               }
            })
        }

        if (this.props.messageHistory !== prevProps.messageHistory) {
            console.log(this.props.lastMessage)
            console.log(this.props.messageHistory)
            let lastMessageData = JSON.parse(this.props.lastMessage.data);
            let messageHistory = this.props.messageHistory;
            const target = messageHistory.find((obj) => (obj.id === lastMessageData.id));
            Object.assign(target, lastMessageData);
            console.log(messageHistory)
        }
    }

    actionModal(id) {
        console.log("action " + id)
        Swal.fire({
            title: 'Do you want to finish order?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let key = "order-" + id;
                const element = document.getElementById(key);
                element.remove();
                // const indexOfObject = this.props.messageHistory.findIndex((obj => obj.id === id));
                // this.props.messageHistory.splice(indexOfObject, 1);
            }
        });
    }

    render() {
        return (
            <>
                <Page className="home">
                    <div className="col-md-12" style={{padding: '0'}}>
                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-body">
                                    <span>The WebSocket is currently status : <span style={{color: this.props.connectionStatus === "Closed" ? "red" : "green"}}>{this.props.connectionStatus}</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3" style={{padding: "0", display: "flex", overflowX: "hidden"}}>
                            {
                                this.props.messageHistory.map((message, idx) => {
                                    let bucket = JSON.parse(message.bucket)
                                    return (
                                        <div className="col-md-3" id={"order-"+message.id}>
                                            <div className="card" style={{maxHeight: "80vh", height: "70vh",cursor: "pointer"}} onClick={() => this.actionModal(message.id)} >
                                                <div className="card-body" style={{overflowY: "auto"}}>
                                                    <div>ORDER: {message.id} ({message.total_data})</div>
                                                    {
                                                        bucket.map((value,key) => {
                                                            return (
                                                                <>
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-8 mt-3">
                                                                                <div style={{
                                                                                    lineHeight: '1px',
                                                                                    color: '#86622c'
                                                                                }}>
                                                                                    <p style={{lineHeight: "13px"}}>{value.name}</p>
                                                                                    <div>
                                                                                        <p>
                                                                                            <small style={{textTransform: 'uppercase'}}>: {value.type} ({value.price})</small>
                                                                                        </p>
                                                                                        <p>
                                                                                            <small style={{textTransform: 'uppercase'}}>: {value.sugarLevel}</small>
                                                                                        </p>
                                                                                        {
                                                                                            value.idAddOn === true &&
                                                                                            <p>
                                                                                                <small style={{textTransform: 'uppercase'}}>: Extra Shot (10)</small>
                                                                                            </p>
                                                                                        }
                                                                                        {
                                                                                            value.isOrganic === true &&
                                                                                            <p>
                                                                                                <small style={{textTransform: 'uppercase'}}>: Organic (20)</small>
                                                                                            </p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2 mt-3"
                                                                                 style={{textAlign: 'end',whiteSpace: 'nowrap'}}>
                                                                                <div style={{color: '#86622c'}}>
                                                                                    <p>X{value.totalNumberMenu}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {/*<span key={idx}>{message ? message.data : null}</span>*/}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Page>

            </>
        )
    }
}


const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

const mapDispatchToProps = {setValues, setModalValues};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Order);



const Page = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2% 2% 0px;
  background-image: url(${imgList.bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  font-family: 'DB-Adman-X', 'tahoma';

  .setFont {
    font-family: 'DB-Adman-X', 'tahoma';
  }

  .activeBtn {
    background-color: greenyellow;
  }

  hr {
    border: 1px solid #9CDCF9;
  }

  @media screen and (max-width: 450px) {
    padding: 58px 0 0px;
  }

  .btnMainMenu {
    width: 100%;
    height: 100%;
    color: #757575;
    font-size: 1.2rem;
      // background-image: url(${imgList.icon_coffee});
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: center;
  }

  .layoutBTN {
    padding: 0 2%;
  }


  .home {
    &__social {
      display: flex;
      justify-content: space-between;
    }

    &__imagetitle {
      margin: 0 auto;
    }

    &__inner {
      width: 100%;
      background: #fff;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        flex-direction: column-reverse;
      }
    }

    &__detail {
      width: 100%;
      padding: 5% 6%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0px 3px 6px #00000029;
      background: #F8F9FA;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        padding: 8%;
        text-align: center;
      }
    }

    &__detail-2 {
      width: 100%;
      padding: 4% 5%;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        padding: 8%;
        text-align: center;
      }
    }

    &__head {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 1.6rem;
      color: #fff;
      line-height: 5;
      margin-bottom: 2%;
      position: absolute;
      top: 50%;
      left: 30%;
      transform: translate(-50%, -50%);
      @media screen and (orientation: portrait) , (max-width: 765px) {
        margin-bottom: 5%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__sub-head {
      font-size: 1.5em;
      color: #4E5456;
      line-height: 1;
      margin-bottom: 2%;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        margin-bottom: 5%;
      }
    }

    &__titletext {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 10rem;
      color: #fff;
      line-height: 1;
      margin: 10% 0;
      letter-spacing: 2rem;
      @media screen and (orientation: portrait) {
        font-size: 10rem;
        letter-spacing: 2rem;
        margin-left: 8%;
        text-align: -webkit-center;
      }

      @media screen and (max-width: 765px) {
        font-size: 2.7rem;
        letter-spacing: 1rem;
        margin: 0;
      }

    }

    &__text {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 4rem;
      color: #fff;
      line-height: 1;
      margin: 10% 0;

      @media screen and (orientation: portrait) {
        font-size: 3rem;
        white-space: nowrap;
      }
      @media screen and (max-width: 765px) {
        font-size: 1.2rem;
        white-space: nowrap;
      }

    }

    &__detail-2-inner {
      width: 100%;
      display: flex;
      align-items: inherit;
      justify-content: center;
      @media screen and (max-width: 1280px) {
        flex-wrap: wrap;
        max-width: 800px;
        margin: 0 auto;
      }
    }

    &__btn {
      background: #ED592F;
      color: #fff;
      font-size: 1em;
      text-align: center;
      width: 8em;
      border-radius: 1.2em;
      padding: .5em 0;
      margin: 3% auto 0;
      display: block;
      cursor: pointer;
      transition: .3s opacity;

      &:hover {
        opacity: .9;
      }
    }
  }
`;