import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import {setValues} from '../store/redux';
import {setValues as setModalValues} from '../features/modal/redux';
import Swal from 'sweetalert2'
import {imgList} from './../constants/ImagesList';
import {
    LoadingModal
} from '../features/modal';
import Header from "../features/headermain";
import Intro from "../features/intro";
import Do from "../features/do";
import Result from "../features/result";
import Footer from "../features/footer";
import styled from "styled-components";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import {mdiCartOutline} from '@mdi/js';
import Icon from "@mdi/react";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";
import html2canvas from 'html2canvas';
import {jsPDF} from "jspdf";


const customStyles = {
    content: {
        fontFamily: "'DB-Adman-X', 'tahoma'",
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
};

const numpad = [
    {
        id:1
    },
    {
        id:2
    },
    {
        id:3
    },
    {
        id:4
    },
    {
        id:5
    },
    {
        id:6
    },
    {
        id:7
    },
    {
        id:8
    },
    {
        id:9
    },
]

class Empty extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            layoutActive: 1,
            setIsOpen: false,
            objectSelect: '',
            price: 0,
            sugarLevel: '',
            selectItemName: '',
            idType: 0,
            typeName: '',
            idSugar: 0,
            bucket: [],
            totalPrice: 0,
            numberTotal: 1,
            id: 1,
            statusEdit: false,
            idEdit: 0,

            data_coffee: [],
            data_non_coffee: [],
            data_light: [],
            data_smoothie: [],
            data_soda: [],
            sugar_level: [],
            data_bakery: [],
            data_special: [],

            number: 0,
            orders: [],
            orderIdSelect: 0,
            totalData:0,
            activeEditOrder: -1,
            statusFree: false,
            idAddOn: false,
            isOrganic: false,

            modalIsOpen: false,
            valueNumpad: "",

            memberID: "",
            firstName: "",
            lastName: "",
            point: "",
            cash: "",
            cashShow: "",
            txt_member_id: "",
            checkFriday: false
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({memberID: event.target.value});
    }

    componentDidMount() {
        this.getMenu();
        this.checkFriday();
    }

    onActiveLayout(tab) {
        this.setState({
            layoutActive: tab
        })
    }

    closeModal() {
        this.setState({
            setIsOpen: false,
            objectSelect: '',
            price: 0,
            sugarLevel: '',
            selectItemName: '',
            idType: 0,
            typeName: '',
            idSugar: 0,
            idAddOn: false,
            isOrganic: false,
            numberTotal: 1,
            idEdit: 0,
            statusFree: false,
            statusEdit: false,
        })
    }

    showDetail(object) {
        this.setState({
            setIsOpen: true,
            objectSelect: object
        })
    }

    selectItemDrink(id, type, price) {
        this.setState({
            price: price,
            typeName: type,
            idType: id
        })
    }

    selectSugar(id, name) {
        this.setState({
            sugarLevel: name,
            idSugar: id
        })
    }

    addToBucket(object, name, price, sugarLevel, sugarLevelID, typeName, typeID, totalNumberMenu,statusFree,statusAddon,statusOrganic) {

        if (price !== 0 && sugarLevel !== '' && typeName !== '') {
            const obj = {
                id: this.state.id,
                name: name,
                price: price,
                sugarLevel: sugarLevel,
                sugarLevelID: sugarLevelID,
                type: typeName,
                type_id: typeID,
                totalNumberMenu: totalNumberMenu,
                object: object,
                statusFree: statusFree,
                idAddOn: statusAddon,
                isOrganic: statusOrganic,
            }

            this.setState({
                setIsOpen: false,
                objectSelect: '',
                price: 0,
                sugarLevel: '',
                selectItemName: '',
                idType: 0,
                typeName: '',
                idSugar: 0,
                numberTotal: 1,
                statusFree: false,
                idAddOn: false,
                isOrganic: false,
            })

            let priceConst = 1;
            if (statusFree) {
                priceConst = 0
            }


            this.setState(prevState => {
                let totalPriceCal;
                totalPriceCal = prevState.totalPrice + (((parseInt(price) + (statusAddon ? 10 : 0) + (statusOrganic ? 20 : 0) ) * totalNumberMenu)*priceConst)

                console.log(totalPriceCal)
                console.log((prevState.totalData + totalNumberMenu)%2)

                return {
                    totalPrice: totalPriceCal,
                    id: prevState.id + 1,
                    totalData: prevState.totalData + totalNumberMenu,
                }
            })


            this.state.bucket.push(obj);
            console.log(this.state.bucket.length)
            console.log(this.state.bucket)
        }
    }

    updateBucket(id, object, name, price, sugarLevel, sugarLevelID, typeName, typeID, totalNumberMenu,statusFree,statusAddon,statusOrganic) {
        if (sugarLevel !== '' && typeName !== '') {

            let objIndex = this.state.bucket.findIndex((obj => obj.id === id));
            this.state.bucket[objIndex].sugarLevel = sugarLevel
            this.state.bucket[objIndex].sugarLevelID = sugarLevelID
            this.state.bucket[objIndex].type = typeName
            this.state.bucket[objIndex].type_id = typeID
            this.state.bucket[objIndex].totalNumberMenu = totalNumberMenu
            this.state.bucket[objIndex].statusFree = statusFree
            this.state.bucket[objIndex].price = price
            this.state.bucket[objIndex].idAddOn= statusAddon
            this.state.bucket[objIndex].isOrganic= statusOrganic

            this.setState({
                setIsOpen: false,
                objectSelect: '',
                price: 0,
                sugarLevel: '',
                selectItemName: '',
                idType: 0,
                typeName: '',
                idSugar: 0,
                numberTotal: 1,
                statusEdit: false,
                idEdit: 0,
                idAddOn: false,
                isOrganic: false,
            })


            let totalPriceWhenUpdate = 0;
            let totalDataWhenUpdate = 0;
            this.state.bucket.map((value, key) => {
                let priceConst = 1;
                if (value.statusFree) {
                    priceConst = 0
                }

                totalPriceWhenUpdate += (((parseInt(value.price) + (value.idAddOn ? 10 : 0) + (value.isOrganic ? 20 : 0)) * value.totalNumberMenu)*priceConst);
                totalDataWhenUpdate += value.totalNumberMenu;
            })

            this.setState(prevState => {
                return {
                    totalPrice: totalPriceWhenUpdate,
                    totalData: totalDataWhenUpdate
                }
            })

        }
    }

    increaseNumber() {
        this.setState(prevState => {
            return {numberTotal: prevState.numberTotal + 1}
        })
    }

    reduceNumber() {
        this.setState(prevState => {
            if (prevState.numberTotal <= 1) {
                return {numberTotal: 1}
            } else {
                return {numberTotal: prevState.numberTotal - 1}
            }
        })
    }

    editData(data) {
        this.setState({
            setIsOpen: true,
            objectSelect: data.object,
            price: data.price,
            sugarLevel: data.sugarLevel,
            selectItemName: '',
            idType: data.type_id,
            typeName: data.type,
            idSugar: data.sugarLevelID,
            numberTotal: data.totalNumberMenu,
            statusEdit: true,
            idEdit: data.id,
            statusFree: data.statusFree,
            idAddOn: data.idAddOn,
            isOrganic: data.isOrganic
        })
    }

    removeData(id) {
        this.state.bucket.splice(this.state.bucket.findIndex(v => v.id === id), 1);
        this.setState({
            setIsOpen: false,
            objectSelect: '',
            price: 0,
            sugarLevel: '',
            selectItemName: '',
            idType: 0,
            typeName: '',
            idSugar: 0,
            numberTotal: 1,
            idEdit: 0,
            statusEdit: false,
            idAddOn:false,
            isOrganic:false,
        })

        let totalPriceWhenUpdate = 0;
        let totalDataWhenUpdate = 0;
        this.state.bucket.map((value, key) => {
            totalPriceWhenUpdate += (value.price * value.totalNumberMenu);
            totalDataWhenUpdate += value.totalNumberMenu;
        })

        this.setState(prevState => {
            return {
                totalPrice: totalPriceWhenUpdate,
                totalData: totalDataWhenUpdate
            }
        })
    }

    getMenu() {
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_MENU, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.status === 200) {
                response.json().then(response => {
                    if (response.status) {
                        this.setState({
                            data_coffee: response.data.menu_coffee,
                            data_non_coffee: response.data.menu_non_coffee,
                            data_light: response.data.menu_light,
                            data_smoothie: response.data.menu_smoothie,
                            data_soda: response.data.menu_soda,
                            data_bakery: response.data.menu_bakery,
                            data_special: response.data.menu_special,
                            sugar_level: response.data.sugar_level,
                            number: response.number,
                            orders: response.orders
                        })
                    }
                });
            } else {
                console.log('API Error Network: ', response.error)
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    confirmButtonColor: '#0F3492',
                })
            }
        }).catch(error => {
            console.log('API Error : ', error)
        })
    }

    clickNumpad(data) {
        this.setState(prevState => {
            if (data === -1) {
                return {
                    valueNumpad: prevState.valueNumpad.slice(0, -1)
                }
            } else {
                return {
                    valueNumpad: prevState.valueNumpad.toString() + data.toString(),
                }
            }

        })
    }

    saveData(bucket, totalprice) {
        Swal.fire({
            title: 'Do you want to place order?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Please Wait !',
                    html: 'data uploading',// add html attribute if you want or remove
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    },
                });
                fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_SAVE_DATA, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'bucket': bucket,
                        'totalprice': totalprice,
                        'orderIdSelect': this.state.orderIdSelect,
                        'totalData' : this.state.totalData,
                        'cash' : this.state.valueNumpad,
                        'change' : (parseInt(this.state.valueNumpad) - parseInt(totalprice)),
                        'memberId' : this.state.txt_member_id
                    })
                }).then(response => {
                    Swal.close();
                    if (response.status === 200) {
                        response.json().then(response => {
                            if (response.status) {
                                window.location.reload();
                            }
                        });
                    } else {
                        console.log('API Error Network: ', response.error)
                        Swal.fire({
                            icon: 'error',
                            title: 'แจ้งเตือน',
                            text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                            confirmButtonColor: '#0F3492',
                        })
                    }
                }).catch(error => {
                    console.log('API Error : ', error)
                })
            }
        });
    }

    checkFriday() {
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_CHECK_FRIDAY, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.status === 200) {
                response.json().then(response => {
                    this.setState({
                        checkFriday: response.status
                    })
                });
            } else {
                console.log('API Error Network: ', response.error)
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    confirmButtonColor: '#0F3492',
                })
            }
        }).catch(error => {
            console.log('API Error : ', error)
        })
    }

    editOrder(data, total_price, orderId,total_data,member_id) {
        if (member_id !== null) {
            this.getMember(member_id)
            this.setState({
                memberID: member_id
            })
        } else {
            this.clearDataDetails()
        }
        this.setState({
            bucket: JSON.parse(data),
            totalPrice: total_price,
            orderIdSelect: orderId,
            totalData: total_data,
            activeEditOrder: orderId,
            id: JSON.parse(data).length + 1,
        })

    }


    clearDataDetails() {
        this.setState({
            setIsOpen: false,
            objectSelect: '',
            price: 0,
            sugarLevel: '',
            selectItemName: '',
            idType: 0,
            typeName: '',
            idSugar: 0,
            numberTotal: 1,
            bucket: [],
            totalPrice: 0,
            orderIdSelect: 0,
            statusEdit: false,
            activeEditOrder: -1,
            totalData: 0,
            idAddOn: false,
            isOrganic: false,
            id:1,
            memberID: "",
            firstName: "",
            lastName: "",
            point: "",
            cash: "",
            cashShow: "",
            txt_member_id: ""
        })
    }

    setFree(free) {
        this.setState({
            statusFree: !free
        })
    }

    Addon() {
        this.setState({
            idAddOn: !this.state.idAddOn
        })
    }

    AddOrganic() {
        this.setState({
            isOrganic: !this.state.isOrganic
        })
    }

    closeModalNumpad() {
        this.setState({
            modalIsOpen: false,
            valueNumpad: ""
        })
    }

    placeOrder(bucket,total) {
        this.setState({
            modalIsOpen: true
        })
    }

    getMember(memberId) {
        if (memberId !== "") {
            fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_PROFILE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'memberId': memberId,
                })
            }).then(response => {
                Swal.close();
                if (response.status === 200) {
                    response.json().then(response => {
                        if (response.status) {
                            this.setState({
                                firstName: response.data.first_name,
                                lastName: response.data.last_name,
                                point: response.data.pointFormat,
                                cash: response.data.cash,
                                cashShow: response.data.cashFormat,
                                txt_member_id: response.data.txt_member_id,
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'แจ้งเตือน',
                                text: response.message,
                                confirmButtonColor: '#0F3492',
                            })
                            this.setState({
                                firstName: "",
                                lastName: "",
                                point: "",
                                cash: "",
                                cashShow: "",
                                txt_member_id: ""
                            })
                        }
                    });
                } else {
                    console.log('API Error Network: ', response.error)
                    Swal.fire({
                        icon: 'error',
                        title: 'แจ้งเตือน',
                        text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        confirmButtonColor: '#0F3492',
                    })
                }
            }).catch(error => {
                console.log('API Error : ', error)
            })
        }
    }

    saveDataMember(bucket, totalprice) {
        if (parseInt(this.state.cash) >= parseInt(totalprice)) {
            Swal.fire({
                title: 'Do you want to place order?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Swal.fire({
                        title: 'Please Wait !',
                        html: 'data uploading',// add html attribute if you want or remove
                        allowOutsideClick: false,
                        onBeforeOpen: () => {
                            Swal.showLoading()
                        },
                    });
                    fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_SAVE_DATA_MEMBER, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            'bucket': bucket,
                            'totalprice': totalprice,
                            'orderIdSelect': this.state.orderIdSelect,
                            'totalData' : this.state.totalData,
                            'cash' : totalprice,
                            'change' : 0,
                            'memberId' : this.state.txt_member_id
                        })
                    }).then(response => {
                        Swal.close();
                        if (response.status === 200) {
                            response.json().then(response => {
                                if (response.status) {
                                    window.location.reload();
                                }
                            });
                        } else {
                            console.log('API Error Network: ', response.error)
                            Swal.fire({
                                icon: 'error',
                                title: 'แจ้งเตือน',
                                text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                                confirmButtonColor: '#0F3492',
                            })
                        }
                    }).catch(error => {
                        console.log('API Error : ', error)
                    })
                }
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เงินไม่พอชำระ',
                confirmButtonColor: '#0F3492',
            })
        }
    }

    handleKeypress(e) {
        if (e.keyCode === 13) {
            console.log(this.state.memberID)
            this.getMember(this.state.memberID)
        }
    }

    render() {
        return (
            <>
                <Page className="home">
                    <div className="col-md-12" style={{padding: '0'}}>
                        <div className="btn btn-warning" style={{position: "absolute", top: "90%", zIndex: "2", transform: "translate(-50%, 0)",fontWeight: "bold",left: "100%"}}>
                            <small><a href="/th">TH</a></small>
                        </div>
                        <div className="row">
                            <div className="col-md-6" style={{height: '95vh', padding: '0'}}>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-2"
                                                             style={{whiteSpace: "nowrap"}}>Member ID :
                                                        </div>
                                                        <div className="col-md-7"><input type="text"
                                                                                         className="form-control"
                                                                                         value={this.state.memberID}
                                                                                         onChange={(e) => this.handleChange(e)}
                                                                                         onKeyDown={(e) => this.handleKeypress(e)}/>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <button className="btn btn-warning"
                                                                    style={{width: "100%"}}
                                                                    onClick={() => this.getMember(this.state.memberID)}>SEARCH
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-3"
                                                     style={{whiteSpace: "nowrap"}}>Member
                                                </div>
                                                <div className="col-md-3 mt-3" style={{whiteSpace: "nowrap"}}>Name
                                                </div>
                                                <div className="col-md-3 mt-3" style={{whiteSpace: "nowrap"}}>Cash
                                                </div>
                                                <div className="col-md-3 mt-3"
                                                     style={{whiteSpace: "nowrap"}}>Point
                                                </div>
                                                <div className="col-md-3"
                                                     style={{whiteSpace: "nowrap"}}>{this.state.txt_member_id}</div>
                                                <div className="col-md-3"
                                                     style={{whiteSpace: "nowrap"}}>{this.state.firstName} {this.state.lastName}</div>
                                                <div className="col-md-3"
                                                     style={{whiteSpace: "nowrap"}}>{this.state.cashShow}</div>
                                                <div className="col-md-3"
                                                     style={{whiteSpace: "nowrap"}}>{this.state.point}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-2 layoutBTN">
                                            <button className="btn btnMainMenu" style={{backgroundColor: '#86e3ce'}}
                                                    onClick={() => this.onActiveLayout(1)}>COFFEE
                                            </button>
                                        </div>
                                        <div className="col-md-2 layoutBTN">
                                            <button className="btn btnMainMenu" style={{backgroundColor: '#d0e6a5'}}
                                                    onClick={() => this.onActiveLayout(2)}>NON-COFFEE
                                            </button>
                                        </div>
                                        <div className="col-md-2 layoutBTN">
                                            <button className="btn btnMainMenu" style={{backgroundColor: '#ffdd94'}}
                                                    onClick={() => this.onActiveLayout(3)}>LIGHT
                                            </button>
                                        </div>
                                        <div className="col-md-2 layoutBTN">
                                            <button className="btn btnMainMenu" style={{backgroundColor: '#ffb284'}}
                                                    onClick={() => this.onActiveLayout(4)}>SMOO-THIE
                                            </button>
                                        </div>
                                        <div className="col-md-2 layoutBTN">
                                            <button className="btn btnMainMenu" style={{backgroundColor: '#fa897b'}}
                                                    onClick={() => this.onActiveLayout(5)}>SODA
                                            </button>
                                        </div>
                                        <div className="col-md-2 layoutBTN">
                                            <button className="btn btnMainMenu" style={{backgroundColor: '#ccabd8'}}
                                                    onClick={() => this.onActiveLayout(6)}>BAKERY
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card"
                                         style={{margin: '2% 0', maxHeight: "550px", overflowY: "auto"}}>
                                        <div className="card-body">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    {
                                                        this.state.layoutActive === 1 && this.state.data_coffee &&
                                                        this.state.data_coffee.map((value, key) => {
                                                            return (
                                                                <div className="col-md-3 mt-3">
                                                                    <button className="btn btnMainMenu"
                                                                            style={{backgroundColor: '#86e3ce'}}
                                                                            onClick={() => this.showDetail(value)}>{value.name}</button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        this.state.layoutActive === 2 && this.state.data_non_coffee &&
                                                        this.state.data_non_coffee.map((value, key) => {
                                                            return (
                                                                <div className="col-md-3 mt-3">
                                                                    <button className="btn btnMainMenu"
                                                                            style={{backgroundColor: '#d0e6a5'}}
                                                                            onClick={() => this.showDetail(value)}>{value.name}</button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        this.state.layoutActive === 3 && this.state.data_light &&
                                                        this.state.data_light.map((value, key) => {
                                                            return (
                                                                <div className="col-md-3 mt-3">
                                                                    <button className="btn btnMainMenu"
                                                                            style={{backgroundColor: '#ffdd94'}}
                                                                            onClick={() => this.showDetail(value)}>{value.name}</button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        this.state.layoutActive === 4 && this.state.data_smoothie &&
                                                        this.state.data_smoothie.map((value, key) => {
                                                            return (
                                                                <div className="col-md-3 mt-3">
                                                                    <button className="btn btnMainMenu"
                                                                            style={{backgroundColor: '#ffb284'}}
                                                                            onClick={() => this.showDetail(value)}>{value.name}</button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        this.state.layoutActive === 5 && this.state.data_soda &&
                                                        this.state.data_soda.map((value, key) => {
                                                            return (
                                                                <div className="col-md-3 mt-3">
                                                                    <button className="btn btnMainMenu"
                                                                            style={{backgroundColor: '#fa897b'}}
                                                                            onClick={() => this.showDetail(value)}>{value.name}</button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        this.state.layoutActive === 6 && this.state.data_bakery &&
                                                        this.state.data_bakery.map((value, key) => {
                                                            return (
                                                                <div className="col-md-3 mt-3">
                                                                    <button className="btn btnMainMenu"
                                                                            style={{backgroundColor: '#ccabd8'}}
                                                                            onClick={() => this.showDetail(value)}>{value.name}</button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4" style={{color: '#757575'}}>
                                <div className="card" style={{height: '100%', position: 'relative'}}>
                                    <div className="card-body">
                                        <div style={{textAlign: 'center'}}>
                                            {
                                                this.state.checkFriday === true &&
                                                <h3 style={{textAlign: 'center',backgroundColor: "#ff5454", color: "white"}}>Promotion GET 2 FREE 1</h3>
                                            }
                                            <h3 style={{textAlign: 'center'}}>Order</h3>
                                            <button className="btn btn-warning"
                                                    onClick={() => this.clearDataDetails()}>clear
                                            </button>
                                        </div>
                                        <div className="col-md-12" style={{fontSize: "1.25rem"}}>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <small>DETAILS</small>
                                                </div>
                                                <div className="col-md-2"
                                                     style={{textAlign: 'end', whiteSpace: 'nowrap'}}>
                                                    <small>TOTAL</small>
                                                </div>
                                                <div className="col-md-2"
                                                     style={{textAlign: 'end', whiteSpace: 'nowrap'}}>
                                                    <small>PRICE(฿)</small>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{border: '1px solid darkgray'}}/>
                                        <div style={{
                                            height: '100%',
                                            overflowY: 'auto',
                                            paddingTop: '5%',
                                            maxHeight: '350px'
                                        }}>
                                            {
                                                this.state.bucket && this.state.bucket.length > 0 ?
                                                    <>
                                                        {
                                                            this.state.bucket.map((value, key) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-md-12"
                                                                             onClick={() => this.editData(value)}>
                                                                            <div className="row">
                                                                                <div className="col-md-8 mt-3">
                                                                                    <div style={{
                                                                                        lineHeight: '1px',
                                                                                        color: '#86622c'
                                                                                    }}>
                                                                                        <p>{value.name}</p>
                                                                                        <div>
                                                                                            <p>
                                                                                                <small
                                                                                                    style={{textTransform: 'uppercase'}}>: {value.type} ({value.price})</small>
                                                                                            </p>
                                                                                            <p>
                                                                                                <small style={{textTransform: 'uppercase'}}>: {value.sugarLevel}</small>
                                                                                            </p>
                                                                                            {
                                                                                                value.idAddOn === true &&
                                                                                                <p>
                                                                                                    <small style={{textTransform: 'uppercase'}}>: Extra Shot (10)</small>
                                                                                                </p>
                                                                                            }
                                                                                            {
                                                                                                value.isOrganic === true &&
                                                                                                <p>
                                                                                                    <small style={{textTransform: 'uppercase'}}>: Organic (20)</small>
                                                                                                </p>
                                                                                            }


                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-2"
                                                                                     style={{textAlign: 'end'}}>
                                                                                    <div style={{color: '#86622c'}}>
                                                                                        <p>X{value.totalNumberMenu}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-2"
                                                                                     style={{textAlign: 'end'}}>
                                                                                    <div style={{color: '#86622c'}}>
                                                                                        <p>{value.statusFree === true ? 0 : ((parseInt(value.price) + (value.idAddOn ? 10 : 0) + (value.isOrganic ? 20 : 0))) * value.totalNumberMenu}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <div style={{textAlign: 'center'}}>
                                                        No detail
                                                    </div>
                                            }
                                        </div>
                                        <div style={{position: 'absolute', bottom: '0', width: '100%',left: '0',padding: '1.25rem',top: "75%"}}>
                                            <hr style={{border: '1px solid darkgray'}}/>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <small style={{ fontSize: "1.5rem"}}>TOTAL ({this.state.totalData})</small>
                                                    </div>
                                                    <div className="col-md-4" style={{textAlign: 'end'}}>
                                                        <small style={{ fontSize: "1.5rem"}}>{this.state.totalPrice}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{display: "flex"}}>
                                                {
                                                    this.state.bucket.length > 0 ?
                                                        <>
                                                            <button className="btn btn-success" style={{width: '100%'}}
                                                                    onClick={() => this.placeOrder(this.state.bucket, this.state.totalPrice)}>PLACE
                                                                ORDER</button>
                                                            <button className="btn btn-warning" style={{width: '100%'}}
                                                                    onClick={() => this.saveDataMember(this.state.bucket, this.state.totalPrice)}>Member Payment</button>
                                                        </>
                                                        :
                                                        <button className="btn btn-secondary" style={{width: '100%'}}>PLACE
                                                            ORDER</button>
                                                }

                                                <div style={{display: 'none'}} id={"divToPrint"}>
                                                    <ComponentToPrint ref={el => (this.componentRef = el)}
                                                                      dataBucket={this.state.bucket}
                                                                      totalPrice={this.state.totalPrice}
                                                                      number={this.state.number}/>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2" style={{color: '#757575'}}>
                                <div className="card" style={{height: '100%'}}>
                                    <div className="card-body">
                                        <div style={{height: '610px', overflowY: 'auto', paddingTop: '5%'}}>
                                            Order ID
                                            <hr style={{border: '1px solid darkgray'}}/>
                                            {
                                                this.state.orders &&
                                                this.state.orders.map((value, key) => {
                                                    return (
                                                        <button className="btn btn-primary"
                                                                style={{width: '100%', marginBottom: '3%',backgroundColor: (this.state.activeEditOrder === value.id ? '#ffc107' : '')}}
                                                                onClick={() => this.editOrder(value.bucket, value.total_price, value.id,value.total_data,value.member_id)}>Order
                                                            : #{value.id}</button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal
                            isOpen={this.state.setIsOpen}
                            onRequestClose={() => this.closeModal()}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div style={{textAlign: 'center', width: '70vw'}} className="setFont">
                                <h4>Detail</h4>
                                <button className="btn btn-warning" onClick={() => this.setFree(this.state.statusFree)}>{this.state.statusFree === true ? 'FREE' : 'NOT FREE'}</button>
                                <hr/>
                                <div className="col-md-12">
                                    <div style={{textAlign: 'left'}}>Menu : {this.state.objectSelect.name} </div>
                                    <div className="row">
                                        {
                                            this.state.objectSelect !== '' && this.state.objectSelect.type.map((value, key) => {
                                                return (
                                                    <div className="col-md-4" style={{padding: '2%'}}>
                                                        <button className={'btn btn-primary '} style={{
                                                            width: '100%',
                                                            textTransform: 'uppercase',
                                                            backgroundColor: (this.state.idType === value.id ? '#3cbe27' : '')
                                                        }}
                                                                onClick={() => this.selectItemDrink(value.id, value.name, value.price)}>{value.name} ({value.price})
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.objectSelect.category_id === 1 &&
                                    <>
                                        <hr/>
                                        <div className="col-md-12  mt-3">
                                            <div style={{textAlign: 'left'}}>Add-on:</div>
                                            <div className="row">
                                                <div className="col-md-3" style={{padding: '2%'}}>
                                                    <button className="btn btn-secondary" style={{
                                                        width: '100%',
                                                        backgroundColor: (this.state.idAddOn === true ? '#3cbe27' : '')
                                                    }}
                                                            onClick={() => this.Addon()}>Extra shot (10)</button>
                                                </div>
                                                <div className="col-md-3" style={{padding: '2%'}}>
                                                    <button className="btn btn-secondary" style={{
                                                        width: '100%',
                                                        backgroundColor: (this.state.isOrganic === true ? '#3cbe27' : '')
                                                    }}
                                                            onClick={() => this.AddOrganic()}>Organic (20)</button>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                    </>
                                }

                                <div className="col-md-12  mt-3">
                                    <div style={{textAlign: 'left'}}>Sugar Level:</div>
                                    <div className="row">
                                        {
                                            this.state.sugar_level.map((value, key) => {
                                                return (
                                                    <div className="col-md-3" style={{padding: '2%'}}>
                                                        <button className="btn btn-secondary" style={{
                                                            width: '100%',
                                                            backgroundColor: (this.state.idSugar === value.id ? '#3cbe27' : '')
                                                        }}
                                                                onClick={() => this.selectSugar(value.id, value.name)}>{value.name}</button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <hr/>
                                <div className="col-md-12 mb-3 mt-3 text-center">
                                    <button className="btn btn-warning" onClick={() => this.reduceNumber()}>-</button>
                                    <small style={{padding: '2%'}}>{this.state.numberTotal}</small>
                                    <button className="btn btn-warning" onClick={() => this.increaseNumber()}>+</button>
                                </div>

                                <div>
                                    <div
                                        className={this.state.statusEdit === true ? "col-md-12" : "col-md-6 offset-md-3"}>
                                        <div className="row">
                                            <div className={this.state.statusEdit === true ? "col-md-4" : "col-md-6"}>
                                                <button className="btn btn-danger" style={{width: '100%'}}
                                                        onClick={() => this.closeModal()}>CLOSE
                                                </button>
                                            </div>
                                            {
                                                this.state.statusEdit === true ?
                                                    <>
                                                        <div className="col-md-4">
                                                            <button className="btn btn-warning" style={{width: '100%'}}
                                                                    onClick={() => this.removeData(this.state.idEdit)}>
                                                                <Icon path={mdiCartOutline} size={1}/>DELETE ITEM
                                                            </button>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <button className="btn btn-success" style={{width: '100%'}}
                                                                    onClick={() => this.updateBucket(this.state.idEdit, this.state.objectSelect, this.state.objectSelect.name, this.state.price, this.state.sugarLevel, this.state.idSugar, this.state.typeName, this.state.idType, this.state.numberTotal,this.state.statusFree,this.state.idAddOn,this.state.isOrganic)}>
                                                                <Icon path={mdiCartOutline} size={1}/>UPDATE BUCKET
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="col-md-6">
                                                        <button className="btn btn-success" style={{width: '100%'}}
                                                                onClick={() => this.addToBucket(this.state.objectSelect, this.state.objectSelect.name, this.state.price, this.state.sugarLevel, this.state.idSugar, this.state.typeName, this.state.idType, this.state.numberTotal,this.state.statusFree,this.state.idAddOn,this.state.isOrganic)}>
                                                            <Icon path={mdiCartOutline} size={1}/>ADD TO BUCKET
                                                        </button>
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={this.state.modalIsOpen}
                            // onAfterOpen={afterOpenModal}
                            onRequestClose={() => this.closeModalNumpad()}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div className="text-center">
                                <h2>PAYMENT</h2>
                                <div className="col-md-12" style={{fontSize: "1.5rem"}}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-7"><p className="text-left">GRAND TOTAL : </p></div>
                                            <div className="col-md-5"><small>{new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(this.state.totalPrice)}</small></div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-7"><p className="text-left">CASH : </p></div>
                                            <div className="col-md-5"><small>{new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(this.state.valueNumpad === "" ? 0 : this.state.valueNumpad)}</small></div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-7"><p className="text-left">CHANGE : </p></div>
                                            <div className="col-md-5"><small>{new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format((parseInt(this.state.valueNumpad === "" ? 0 : this.state.valueNumpad) - parseInt(this.state.totalPrice)))}</small></div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            {
                                                numpad.map((value,key) => {
                                                    return (
                                                        <div className="col-md-4" style={{padding: "2%"}}>
                                                            <div className="card">
                                                                <button className="btn" onClick={() => this.clickNumpad(value.id)}>
                                                                    <div className="card-body" style={{padding: "1rem 0"}}>
                                                                        {value.id}
                                                                    </div>
                                                                </button>
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="col-md-4" style={{padding: "2%"}}>
                                                <div className="card">
                                                    <button className="btn btn-danger" onClick={() => this.clickNumpad(-1)}>
                                                        <div className="card-body" style={{padding: "1rem 0"}}>
                                                            DEL
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-4" style={{padding: "2%"}}>
                                                <div className="card">
                                                    <button className="btn" onClick={() => this.clickNumpad(0)}>
                                                        <div className="card-body" style={{padding: "1rem 0"}}>
                                                            0
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-4" style={{padding: "2%"}}>
                                                <div className="card">
                                                    <button className="btn btn-success" disabled={(parseInt(this.state.valueNumpad === "" ? 0 : this.state.valueNumpad) - parseInt(this.state.totalPrice) < 0)} onClick={() => this.saveData(this.state.bucket, this.state.totalPrice)}>
                                                        <div className="card-body" style={{padding: "1rem 0"}}>
                                                            CONFIRM
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </Page>

            </>
        )
    }
}


const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

const mapDispatchToProps = {setValues, setModalValues};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Empty);


const Video = styled.div`
  @media screen and (orientation: portrait) , (max-width: 450px) {
    display: none;
  }
`

const PagePrint = styled.div`
  position: relative;
  min-height: 100vh;
  padding: 2% 2% 0px;
  display: none;
  font-family: 'DB-Adman-X', 'tahoma';
 
`;

const Page = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2% 2% 0px;
  background-image: url(${imgList.bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  font-family: 'DB-Adman-X', 'tahoma';

  .setFont {
    font-family: 'DB-Adman-X', 'tahoma';
  }

  .activeBtn {
    background-color: greenyellow;
  }

  hr {
    border: 1px solid #9CDCF9;
  }

  @media screen and (max-width: 450px) {
    padding: 58px 0 0px;
  }

  .btnMainMenu {
    width: 100%;
    height: 100%;
    color: #757575;
    font-size: 1.2rem;
      // background-image: url(${imgList.icon_coffee});
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: center;
  }

  .layoutBTN {
    padding: 0 2%;
  }


  .home {
    &__social {
      display: flex;
      justify-content: space-between;
    }

    &__imagetitle {
      margin: 0 auto;
    }

    &__inner {
      width: 100%;
      background: #fff;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        flex-direction: column-reverse;
      }
    }

    &__detail {
      width: 100%;
      padding: 5% 6%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0px 3px 6px #00000029;
      background: #F8F9FA;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        padding: 8%;
        text-align: center;
      }
    }

    &__detail-2 {
      width: 100%;
      padding: 4% 5%;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        padding: 8%;
        text-align: center;
      }
    }

    &__head {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 1.6rem;
      color: #fff;
      line-height: 5;
      margin-bottom: 2%;
      position: absolute;
      top: 50%;
      left: 30%;
      transform: translate(-50%, -50%);
      @media screen and (orientation: portrait) , (max-width: 765px) {
        margin-bottom: 5%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__sub-head {
      font-size: 1.5em;
      color: #4E5456;
      line-height: 1;
      margin-bottom: 2%;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        margin-bottom: 5%;
      }
    }

    &__titletext {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 10rem;
      color: #fff;
      line-height: 1;
      margin: 10% 0;
      letter-spacing: 2rem;
      @media screen and (orientation: portrait) {
        font-size: 10rem;
        letter-spacing: 2rem;
        margin-left: 8%;
        text-align: -webkit-center;
      }

      @media screen and (max-width: 765px) {
        font-size: 2.7rem;
        letter-spacing: 1rem;
        margin: 0;
      }

    }

    &__text {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 4rem;
      color: #fff;
      line-height: 1;
      margin: 10% 0;

      @media screen and (orientation: portrait) {
        font-size: 3rem;
        white-space: nowrap;
      }
      @media screen and (max-width: 765px) {
        font-size: 1.2rem;
        white-space: nowrap;
      }

    }

    &__detail-2-inner {
      width: 100%;
      display: flex;
      align-items: inherit;
      justify-content: center;
      @media screen and (max-width: 1280px) {
        flex-wrap: wrap;
        max-width: 800px;
        margin: 0 auto;
      }
    }

    &__btn {
      background: #ED592F;
      color: #fff;
      font-size: 1em;
      text-align: center;
      width: 8em;
      border-radius: 1.2em;
      padding: .5em 0;
      margin: 3% auto 0;
      display: block;
      cursor: pointer;
      transition: .3s opacity;

      &:hover {
        opacity: .9;
      }
    }
  }
`;